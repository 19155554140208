'use client'

import { Screen } from 'constants/tracking/screens'
import { CurrencyAmountModel } from 'types/models/currency-amount'
import { TrackingEvent } from '@marketplace-web/shared/event-tracker'

import ElectronicsVerificationModalBuyer from '../ElectronicsVerificationBuyerModal'
import ElectronicsVerificationSellerModal from '../ElectronicsVerificationSellerModal'

type Props = {
  show: boolean
  isViewingOwnItem: boolean
  onClose: () => void
  verificationFee?: CurrencyAmountModel
  screen: Screen
  /** Optionally overrides the default user.view tracking event that is triggered when the modal is in view. */
  viewEvent?: TrackingEvent
}

const ElectronicsVerification = (props: Props) => {
  const { isViewingOwnItem, ...rest } = props

  if (isViewingOwnItem) {
    return <ElectronicsVerificationSellerModal {...rest} />
  }

  return <ElectronicsVerificationModalBuyer {...rest} />
}

export default ElectronicsVerification
