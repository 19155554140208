'use client'

import { Divider, Cell, Text, Container } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  isSellerBusiness: boolean
}

const LegalNote = ({ isSellerBusiness }: Props) => {
  const translate = useTranslate('item_price_breakdown_detailed')
  const userTypeSuffix = isSellerBusiness ? '_pro' : ''

  return (
    <>
      <Divider />
      <Container styling={Container.Styling.Narrow}>
        <Cell styling={Cell.Styling.Narrow}>
          <Text
            as="h4"
            testId="legal-note"
            type={Text.Type.Caption}
            text={translate(`legal_note${userTypeSuffix}`)}
          />
        </Cell>
      </Container>
    </>
  )
}

export default LegalNote
