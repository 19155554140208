'use client'

import { KeyboardEvent } from 'react'
import { Icon, Spacer, Text } from '@vinted/web-ui'
import { Shield12 } from '@vinted/monochrome-icons'
import { useIntl } from 'react-intl'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { formatCurrencyAmount } from '_libs/utils/formatString'
import { CurrencyAmountModel } from 'types/models/currency-amount'

import ServiceTitle from '../ServiceTitle'
import SellerText from '../SellerText'

type Props = {
  isItemOwner?: boolean
  isSellerBusiness: boolean
  totalItemPrice: CurrencyAmountModel
  shouldTrimTitle?: boolean
  shouldMinimiseTitle?: boolean
  onClick: () => void
  onKeyDown: (event: KeyboardEvent) => void
}

const InlinePrice = ({
  totalItemPrice,
  isItemOwner,
  isSellerBusiness,
  shouldTrimTitle,
  shouldMinimiseTitle,
  onClick,
  onKeyDown,
}: Props) => {
  const translate = useTranslate('item.includes_buyer_protection')
  const { locale } = useIntl()

  const formattedTotalPrice = formatCurrencyAmount(totalItemPrice, locale)

  return (
    <div className="u-flexbox u-align-items-flex-start">
      <button
        className="u-flexbox u-align-items-center u-flex-wrap"
        tabIndex={0}
        aria-label={translate('title', { price: formattedTotalPrice })}
        onKeyDown={onKeyDown}
        onClick={onClick}
        type="button"
      >
        <span className="u-flexbox u-align-items-baseline u-flex-wrap">
          <Text
            type={shouldMinimiseTitle ? Text.Type.Subtitle : Text.Type.Body}
            text={formattedTotalPrice}
            clickable
            underline={false}
            as="span"
          />
          <Spacer size={Spacer.Size.XSmall} orientation={Spacer.Orientation.Vertical} as="span" />
          <ServiceTitle
            isItemOwner={isItemOwner}
            isSellerBusiness={isSellerBusiness}
            shouldMinimiseTitle={shouldMinimiseTitle}
            shouldTrimTitle={shouldTrimTitle}
          />
        </span>
        <Spacer size={Spacer.Size.XSmall} orientation={Spacer.Orientation.Vertical} as="span" />
        <Icon color={Icon.Color.Primary} name={Shield12} testId="service-fee-included-icon" />
        {isItemOwner && <SellerText shouldMinimiseTitle={shouldMinimiseTitle} />}
      </button>
    </div>
  )
}

export default InlinePrice
