'use client'

import { useState } from 'react'
import { Icon } from '@vinted/web-ui'
import { BuyerProtectionShield32 } from '@vinted/multichrome-icons'
import { InfoCircle16 } from '@vinted/monochrome-icons'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { EscrowFeeBuyerProtectionModel } from 'types/models/escrow-fees'
import { clickEvent } from '_libs/common/event-tracker/events'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import FeeDetails from '../FeeDetails'
import BuyerProtectionModal from '../../BuyerProtectionModal'

type Props = {
  buyerProtection?: EscrowFeeBuyerProtectionModel
  isSellerBusiness: boolean
  isItemOwner: boolean
  itemId: number
}

const BuyerProtectionFee = ({ buyerProtection, isSellerBusiness, isItemOwner, itemId }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const translate = useTranslate('item_price_breakdown_detailed')
  const { track } = useTracking()

  if (!buyerProtection) return null

  const showDiscountNote = !!buyerProtection?.showPriceRangeRuleNote

  const protectionFeeTranslation = isSellerBusiness
    ? 'buyer_protection_fee_pro'
    : 'buyer_protection_fee'

  const handelInfoIconClick = () => {
    setIsModalVisible(true)

    track(
      clickEvent({
        screen: Screen.PriceBreakdownModal,
        target: ClickableElement.ServiceFeeInfo,
        targetDetails: JSON.stringify({
          item_id: itemId,
        }),
      }),
    )
  }

  const renderInfoIcon = () => (
    <>
      <button
        className="u-flexbox u-align-self-center u-cursor-pointer"
        data-testid="item-pricing-details-icon"
        onClick={handelInfoIconClick}
        type="button"
      >
        <Icon name={InfoCircle16} color={Icon.Color.GreyscaleLevel2} />
      </button>
      <BuyerProtectionModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        sellerIsBusinessUser={isSellerBusiness}
      />
    </>
  )

  return (
    <FeeDetails
      testId="item-price-breakdown-service-fee-cell"
      feeTitle={translate(protectionFeeTranslation)}
      escrowFee={buyerProtection}
      prefix={
        <Icon
          name={BuyerProtectionShield32}
          aria={{
            'aria-hidden': 'true',
          }}
        />
      }
      infoIcon={renderInfoIcon()}
      isItemOwner={isItemOwner}
      discountNote={showDiscountNote ? translate('bpf_price_range_title') : null}
    />
  )
}

export default BuyerProtectionFee
