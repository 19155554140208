'use client'

import { DashedCheckCircle24, ElectronicsBadge24, Money24 } from '@vinted/monochrome-icons'
import { Button, Cell, ColorCode, Dialog, Icon, Image, Spacer, Text } from '@vinted/web-ui'
import { useEffect } from 'react'

import { TrackingEvent, useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useAsset } from '@marketplace-web/shared/ui-helpers'

import { clickEvent, viewEvent } from '_libs/common/event-tracker/events'
import FaqEntryUrl from 'components/FaqEntryUrl'
import { AccessChannel } from 'constants/access-channel'
import { FaqEntryType } from 'constants/faq-entry'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ViewableElement } from 'constants/tracking/viewable-elements'

type Props = {
  screen: Screen
  show: boolean
  onClose: () => void
  /** Optionally overrides the default user.view tracking event that is triggered when the modal is in view. */
  viewEvent?: TrackingEvent
}

const ElectronicsVerificationSellerModal = ({
  show,
  screen,
  onClose,
  viewEvent: viewEventOverride,
}: Props) => {
  const translate = useTranslate('item.offline_verification.electronics_seller_modal')
  const { track } = useTracking()
  const asset = useAsset('/assets/electronics-verification')

  function handleLearnMoreClick() {
    track(clickEvent({ screen, target: ClickableElement.ElectronicsVerificationLearnMore }))
  }

  function handleClose() {
    track(clickEvent({ screen, target: ClickableElement.ElectronicsVerificationGotIt }))
    onClose()
  }

  useEffect(() => {
    if (!show) return

    track(
      viewEventOverride ||
        viewEvent({
          screen,
          target: ViewableElement.ElectronicsVerificationSellerModal,
        }),
    )
  }, [track, show, screen, viewEventOverride])

  return (
    <Dialog show={show} testId="electronics-verification-seller-modal" hasScrollableContent>
      <div className="u-fill-width">
        <div className="u-overflow-auto">
          <div className="u-flexbox u-justify-content-center">
            <Image
              src={asset('seller_illustration_torn.png')}
              alt={translate('a11y.illustration_alt')}
              scaling={Image.Scaling.Contain}
            />
          </div>

          <Cell>
            <Text
              as="h1"
              text={translate('title')}
              type={Text.Type.Heading}
              width={Text.Width.Parent}
            />
          </Cell>

          <Cell
            testId="electronics-verification-seller-modal-cell-1"
            prefix={<Icon name={DashedCheckCircle24} color={ColorCode.GreyscaleLevel3} />}
            title={translate('cell_1.title')}
            body={
              <>
                {translate('cell_1.body')}
                <FaqEntryUrl
                  type={FaqEntryType.ElectronicsVerificationSeller}
                  accessChannel={AccessChannel.ProductLink}
                  render={url => (
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleLearnMoreClick}
                      data-testid="electronics-verification-seller-modal-learn-more-action"
                    >
                      <Text
                        as="span"
                        clickable
                        theme="inverse"
                        width={Text.Width.Parent}
                        text={translate('cell_1.action')}
                      />
                    </a>
                  )}
                />
              </>
            }
          />

          <Cell
            testId="electronics-verification-seller-modal-cell-2"
            prefix={<Icon name={ElectronicsBadge24} color={ColorCode.GreyscaleLevel3} />}
            title={translate('cell_2.title')}
            body={translate('cell_2.body')}
          />

          <Cell
            testId="electronics-verification-seller-modal-cell-3"
            prefix={<Icon name={Money24} color={ColorCode.GreyscaleLevel3} />}
            title={translate('cell_3.title')}
            body={translate('cell_3.body')}
          />
        </div>

        <Cell>
          <Button
            text={translate('actions.close')}
            styling={Button.Styling.Filled}
            onClick={handleClose}
            testId="electronics-verification-seller-modal-close-button"
          />
        </Cell>
        <Spacer size={Spacer.Size.Large} />
      </div>
    </Dialog>
  )
}

export default ElectronicsVerificationSellerModal
