'use client'

import { Button, Navigation, Divider } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  onClose: () => void
}

const ModalNavigation = ({ onClose }: Props) => {
  const translate = useTranslate('item_price_breakdown_detailed')

  return (
    <>
      <Navigation
        body={translate('breakdown_title')}
        right={
          <Button
            styling={Button.Styling.Flat}
            iconName={X24}
            inline
            onClick={onClose}
            aria={{ 'aria-label': translate('actions.a11y.close') }}
            testId="item-price-breakdown-navigation-close-button"
          />
        }
      />
      <Divider />
    </>
  )
}

export default ModalNavigation
