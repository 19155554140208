'use client'

import { Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  isItemOwner?: boolean
  isSellerBusiness: boolean
  shouldTrimTitle?: boolean
  shouldMinimiseTitle?: boolean
  inline?: boolean
}

const ServiceTitle = ({
  isItemOwner,
  shouldTrimTitle,
  isSellerBusiness,
  shouldMinimiseTitle,
  inline,
}: Props) => {
  const translate = useTranslate('item.includes_buyer_protection')
  const getTextSize = () => (shouldMinimiseTitle ? Text.Type.Caption : Text.Type.Subtitle)
  const userTypeSuffix = isSellerBusiness ? '_pro' : ''

  const titleTestId = isSellerBusiness
    ? 'service-fee-included-title-pro'
    : 'service-fee-included-title'

  const formatTitle = () => {
    const isTrimmed = shouldTrimTitle || isItemOwner ? '_trimmed' : ''

    return translate(`title_without_price${userTypeSuffix}${isTrimmed}`)
  }

  return (
    <Text
      clickable
      underline={false}
      text={formatTitle()}
      type={getTextSize()}
      testId={titleTestId}
      inline={inline}
      as="span"
      tabIndex={-1}
    />
  )
}

export default ServiceTitle
