import { Cell, Image, Text } from '@vinted/web-ui'

type Props = {
  itemPhotoSrc?: string | null
  itemPrice: string
  itemTitle: string
}

const ItemDetails = ({ itemPrice, itemTitle, itemPhotoSrc }: Props) => (
  <Cell
    testId="item-price-breakdown-price-cell"
    styling={Cell.Styling.Narrow}
    prefix={
      itemPhotoSrc && (
        <div data-testid="item-details-img">
          <Image
            src={itemPhotoSrc}
            styling={Image.Styling.Rounded}
            ratio={Image.Ratio.Square}
            size={Image.Size.Medium}
            aria={{
              'aria-hidden': 'true',
            }}
          />
        </div>
      )
    }
    title={<div className="c-text--truncated">{itemTitle}</div>}
    body={<Text text={itemPrice} type={Text.Type.Title} theme="amplified" as="div" />}
  />
)

export default ItemDetails
