import { PhotoDto, PhotoThumbnailDto } from 'types/dtos/photo'
import { ReactionDto } from 'types/dtos/reaction'
import { PhotoModel, PhotoThumbnailModel } from 'types/models/photo'
import { ReactionModel } from 'types/models/reaction'

export const transformPhotothumbnailDto = <T = string>(
  photoThumbnailDto: PhotoThumbnailDto<T>,
): PhotoThumbnailModel<T> => ({
  type: photoThumbnailDto.type,
  url: photoThumbnailDto.url,
  width: photoThumbnailDto.width,
  height: photoThumbnailDto.height,
  isOriginalSize: photoThumbnailDto.original_size,
})

export const transformPhotoThumbnailDtos = <T = string>(
  photoThumbnailDtos: Array<PhotoThumbnailDto<T>>,
): Array<PhotoThumbnailModel<T>> => photoThumbnailDtos.map(transformPhotothumbnailDto)

export const transformReactionDto = (reactionDto: ReactionDto): ReactionModel => ({
  id: reactionDto.id,
  type: reactionDto.type,
})

export const transformPhotoDto = <T = string>(photoDto: PhotoDto<T>): PhotoModel<T> => ({
  id: photoDto.id,
  width: photoDto.width,
  height: photoDto.height,
  url: photoDto.url,
  tempUuid: photoDto.temp_uuid,
  dominantColor: photoDto.dominant_color,
  dominantColorOpaque: photoDto.dominant_color_opaque,
  isSuspicious: photoDto.is_suspicious,
  fullSizeUrl: photoDto.full_size_url,
  orientation: photoDto.orientation ? photoDto.orientation : 0,
  thumbnails: transformPhotoThumbnailDtos(photoDto.thumbnails),
  isHidden: photoDto.is_hidden,
  reaction: photoDto.reaction ? transformReactionDto(photoDto.reaction) : undefined,
})

export const transformPhotoDtos = (photoDtos: Array<PhotoDto>): Array<PhotoModel> =>
  photoDtos.map(transformPhotoDto)
