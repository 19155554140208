'use client'

import { Spacer, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  shouldMinimiseTitle?: boolean
}

const SellerText = ({ shouldMinimiseTitle }: Props) => {
  const translate = useTranslate('item.includes_buyer_protection')
  const getTextSize = () => (shouldMinimiseTitle ? Text.Type.Caption : Text.Type.Subtitle)

  return (
    <>
      <Spacer size={Spacer.Size.XSmall} orientation={Spacer.Orientation.Vertical} />
      <Text
        clickable
        underline={false}
        text={translate('price_for_buyer')}
        type={getTextSize()}
        as="p"
      />
    </>
  )
}

export default SellerText
