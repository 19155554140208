import { transformPhotoThumbnailDtos } from 'data/transformers/photo'

import { ItemPhotoDto, ItemPhotoModel } from '../types/item'

/**
 * @deprecated
 */
export const transformItemPhotoDto = (photo: ItemPhotoDto): ItemPhotoModel => ({
  id: photo.id,
  width: photo.width,
  height: photo.height,
  tempUuid: photo.temp_uuid,
  url: photo.url,
  thumbnails: transformPhotoThumbnailDtos(photo.thumbnails),
  dominantColor: photo.dominant_color,
  dominantColorOpaque: photo.dominant_color_opaque,
  isMain: photo.is_main,
  isSuspicious: photo.is_suspicious,
  fullSizeUrl: photo.full_size_url,
  imageNo: photo.image_no,
})

/**
 * @deprecated
 */
export const transformItemPhotoDtos = (photos: Array<ItemPhotoDto>): Array<ItemPhotoModel> =>
  photos.map(transformItemPhotoDto)
